module.exports = {
	lang: {
		'暂无该订单信息,请核对订单id后重试': 'There is currently no information available for this order. Please verify the order ID and try again',
		'登录失败': 'Login failed',
		'登录信息错误': 'Login information error',
		'管理员验证': 'Administrator verification',
		'管理员账号': 'Administrator account',
		'管理员密码': 'Administrator password',
		'请输入管理员账号': 'Please enter the administrator account',
		'请输入管理员密码': 'Please enter the administrator password',
		'暂无数据': 'No data available',
		'刷新': 'refresh',
		'请输入正确的UID，否则无法获得交易返佣': 'Please enter the correct UID, otherwise you cannot obtain a transaction rebate',
		'绑定UID': 'Binding UID',
		'绑定API': 'Binding API',
		'选择平台': 'Select Platform',
		'注：适用于新用户新增API操作。': 'Note: Applicable to new API operations for new users.',
		'注：适用于原平台原账号API到期换新操作，保留全部数据继续执行。': 'Note: It is applicable to the API renewal operation of the original account on the original platform upon expiration, and all data is retained for continued execution.',
		'注：适用于更换平台或账号API，并平仓掉原账号持仓资产。': 'Note: It is applicable to changing the platform or account API, and liquidating the assets held by the original account.',
		'注：忽略原有账号数据及资产，直接更换新平台新账号API操作。': 'Note: Ignore the original account data and assets, and directly replace the new platform with a new account API operation.',
		'新增API': 'Newly add API',
		'原API到期续期': 'Renewal API',
		'平仓换新号API': 'Close a position',
		'直接换新号API': 'Brand new API',
		'初始金额不能为空': 'Initial amount cannot be blank',
		'初始时间不能为空': 'Initial time cannot be empty',
		'确认提交吗？': 'Are you sure to submit?',
		'提币金额不能为空': 'Withdrawal amount cannot be empty',
		'充值金额不能为空': 'Recharge amount cannot be empty',
		'数据不能为空': 'Data cannot be empty',
		'保存成功': 'Saved successfully',
		'确认保存参数设置吗？': 'Are you sure to save the parameter settings?',
		'API更换成功': 'API replacement succeeded',
		'API添加成功': 'API added successfully',
		'确定绑定吗？': 'Are you sure to bind?',
		'请输入账号的UID': 'Please enter the UID of your account',
		'UID无效': 'Invalid UID',
		'请输入Access Key': 'Please enter an Access Key',
		'请输入Secret Key': 'Please enter an Secret Key',
		'开启': '开启',
		'关闭': '关闭',
		'暂未开放': 'Not yet open',
		'恢复上次设置操作成功': 'The operation to restore the last settings succeeded',
		'暂无可恢复的设置，请添加参数并保存参数设置': 'There are currently no recoverable settings. Please add parameters and save the parameter settings',
		'请自行登录账号所在平台前端查看': 'Please log in to the front end of the platform where your account is located to view it',
		'确认平仓吗？': 'Are you sure to close the position?',
		'策略正在运行，请关闭后再操作': 'Policy is running, please close it before operation',
		'API失效': 'API failure',
		'平仓成功': 'Closing successfully',
		'确认提示': 'Confirmation prompt',
		'提示': 'Tips',
		'确定': 'Confirm',
		'取消': 'Cancel',
		'确定退出吗？':'Are you sure to exit?',
		'天': 'day',
		'小时': 'hour',
		'设置': 'set',
		'币种': 'currency',
		'市场价': 'market value',
		'24H额': '24h amount',
		'流通数量': 'Circulation quantity',
		'24H涨幅': '24h increase',
		'暂无': 'None at present',
		'我的工具系统': 'My tool system',
		'暂无该订单信息,请核对订单id后重试': 'The order information is temporarily unavailable. Please check the order ID and try again!',
		wallet: 'Asset / Wallet',
		loginText: 'Log in',
		loginOutText: 'Log out',
		register: 'Sign Up',
		ranking: 'Increase ranking',
		jiaoyi: {
			text1: 'Trading system 24H total',
			text2: '24h single quantity',
			text3: 'Run thread',
			text4: 'Annualized income',
			text5: 'The current data does not include the data generated for external transactions through the AI system of the platform, and the data is refreshed 24 hours;',
			buy: 'buy',
			sell: 'sell',
		},
		robotSet: {
			title1: 'System settings',
			switch: 'System switch',
			apiset: 'API Set up',
			howset: 'How to set',
			workpt: 'Working Platform',
			ptuid: 'Platform UID',
			mobile: 'Account Number',
			xiugai: 'Modify',
			orderid: 'Order No',
			stxj: 'System Patrol Inspection',
			stsx: 'Prescription',
			xufei: 'Renew',
			btn1: 'AI Parameter following',
			btn2: 'Custom Parameters',
			btn3: 'Parameter Reset',
			btn4: 'Return to Home',
			btn5: 'Last Parameter',
			btn6: 'Save Parameters',
			coinset1: 'Position manage',
			coinset2: 'Working interval',
			coinset3: 'Buy conditions',
			coinset4: 'Stop surplus ratio',
			coinset5: 'First order purchase',
			coinset6: 'Price cap',
			coinset7: 'Buy spacing',
			coinset8: 'Stop surplus mode',
			coinset9: 'Cycle duration',
			coinset10: 'Price floor',
			coinset11: 'Buy frequency',
			coinset12: 'Refresh time',
			coinset13: 'Supports up to 4 transaction pairs simultaneously',
			'系统默认': 'System default',
			'手动设置': 'Manual settings',
			'单笔止盈': 'Single pen',
			'多笔止盈': 'Multiple strokes',
			'API正常': 'API OK',
			'API失效': 'API failure',
		},
		mycount: {
			text1: 'Operation',
			text2: 'Start time',
			text3: 'Run time',
			text4: 'Initial amount',
			text5: 'Recharge amount',
			text6: 'Withdraw amount',
			text7: 'Current amount',
			text8: 'Position amount',
			text9: 'Current total value',
			text10: 'Current profit',
			text11: 'Current revenue',
			text12: 'Expected annualization',
			text13: 'Please enter the recharge amount',
			text14: 'Please enter the withdrawal amount',
			tab: 'My system tools',
			biaoti: 'My account',
			zhuangtai: 'running state',
			ccqk: 'Position',
			gzzt: 'Working status',
			lscj: 'History',
			coinname: 'Category',
			num: 'Num',
			ccjj: 'Average',
			total: 'Current Value',
			time: 'Time',
			direction: 'Direction',
			price: 'Price',
			money: 'Money',
			zanwu: 'No data found',
			pingcang: 'Close a position',
			sysclose: 'System Off',
			sysopen: 'System Open',
			'选择日期': 'Select Date',
			'请输入正整数': 'Please enter a positive integer',
			zhu: 'Note: Setting the initial amount will affect position management, please fill it out carefully',
			buy: 'buy',
			sell: 'sell'
		},
		introduce: {
			tit1: 'Provide you with unprecedented services',
			item1: {
				text1: 'Deep aggregation transaction',
				text2: 'Full opening and smooth Trading',
			},
			item2: {
				text1: 'Ai100 Transaction module',
				text2: 'Intelligent and automatic',
			},
			item3: {
				text1: '100% Asset guarantee transparency',
				text2: 'Security and transparency',
			},
			item4: {
				text1: 'International Asset Management',
				text2: 'Multi category · high yield',
			}
		},
		international: {
			tit1: 'Practice an international / compliant / transparent',
			item1: {
				text1: 'Singapore MSB license',
			},
			item2: {
				text1: 'U.S.A MSB license',
			},
			item3: {
				text1: 'Singapore MSB license',
			},
			item4: {
				text1: 'Australia AUSTR license',
			}
		},
		download: {
			text1: 'Enjoy trading anytime, anywhere',
			text2: 'Platform interworking, more convenient to use!'
		},
		footer: {
			item1: {
				tit: 'User support',
				text1: 'Help center',
				text2: 'API documentation',
				text3: 'Rate',
				text4: 'System announcement',
			},
			item2: {
				tit: 'Terms of service',
				text1: 'User agreement',
				text2: 'Privacy clause',
				text3: 'Legal statement',
			},
			item3: {
				tit: 'Business cooperation',
				text1: 'C2C function service',
				text2: 'Institutional account',
				text3: 'Currency application',
				text4: 'City node recruitment'
			},
			item4: {
				tit: 'About us',
				text1: 'About 100SunEX',
				text2: 'About SUNT',
			}
		},
		systemGg: {
			text1: 'System document management center',
			text2: 'System announcement',
			text3: 'common problem',
			text4: 'Help center',
			xiangguan: 'Related content',
		},
		'关于100SunEX近期暂停交易与国际版升级计划的公告；': 'Announcement on the recent suspension of trading and the international version upgrade plan of 100sunex',
		'尊敬的各位用户：': 'Dear users',
		systemList: {
			pt: 'Platform',
			status_text: 'State',
			status_desc: 'Not yet open',
			status_desc1: 'Normal',
			item1: {
				title1: 'USDT mainstream spot automatic trading',
				title2: 'Spot unlevered automatic trading on mainstream platforms',
			},
			item2: {
				title1: 'TOKEN mainstream spot automatic trading',
				title2: 'Spot unlevered automatic trading on mainstream platforms',
			},
			item3: {
				title1: 'TOKEN Automatic contract transaction',
				title2: 'Mainstream asset contract automatic trading system',
			}
		},
		look: 'See more quotes',
		title: 'Customer service records system',
		modifypass: 'Modify Password',
		modifypass1: 'Modify Password',
		oldPassword: 'Old Password:',
		newPassword: 'New Password:',
		surePassword: 'Confirm New Password:',
		loginout: 'Exit',
		cancel: 'Cancel',
		sure: 'Confirm',
		welcome: 'Welcome to Customer service records system',
		menus: {
			menu1: 'Home Page',
			menu2: 'Unsolved',
			menu3: 'Solved',
			menu4: 'Overview',
			menu5: 'Users Management',
			menu6: 'Help',
			menu7: 'Reports Management',
		},
		navigation: {
			infos: {
				text: 'C2C Market',
				text1: 'Quotation',
				text2: 'CEX Transaction',
				text3: 'DEX Transaction',
				text4: 'Ai100 Tool system',
				text5: 'Financial service',
				text6: 'More',
				text7: '100SunGlobal',
				text8: '100SunDSEC',
				text9: 'SunPool',
				text10: 'About SUNT',
				text11: 'Asset / Wallet'
			},
			dex: {
				text1: 'Binance',
				text2: 'Huobi',
				text3: 'OKEX',
				text4: 'UniSwap',
				text5: 'PancakesSwap',
				text6: 'MDEX',
				text7: 'DYDX',
				text8: 'Coinmarket cap',
			}
		},
		login: {
			tit1: 'Account password login',
			tit2: 'Order number login',
			placeholder1: 'Please enter your account number',
			placeholder2: 'Please enter the login password',
			placeholder3: 'Please enter your system order number',
			fotgetPass: 'Forget password',
			confirmLogin: 'Confirm login',
			modifypassSucc: 'password has been updated!',
			error1: 'The original password cannot be empty!',
			error2: 'Password length is 6-18 digits!',
			error3: 'New password cannot be empty!',
			error4: 'Confirm password can not be empty!',
			error5: 'The two passwords are inconsistent, please re-enter!',
			error6: 'Order number cannot be empty!',
		},
		error: {
			e0: "Successfully operated",
			e10001: "Invalid User",
			e10002: "Wrong Password",
			e10003: "Internal Error",
			e10004: "User already exists",
			e10005: "Registration Failed",
			e10006: "User doesn't exist",
			e10007: "Network Error",
			e10008: "User Access Denied",
			e10009: "Parameter Error",
			e10010: "Database Error",
			e10011: "Timed Task Error",
			e10012: "Data Error",
			e10013: "Operation Timed Out",
			e10017: "Program Error",
			e10018: "Token Invalid",
			e10019: "Reply doesn't exist",
			e10020: "session Invalid",
			e10021: "Username can only contain letters, numbers and underscore. Cannot begin with numbers",
			e10022: "Failed to add a new user",
			e10023: "Incorrect password format",
			e10024: "Cannot use the previous password",
			e10025: "Administrator with password reset doesn't exist",
			e10026: "Cannot reset Super Password",
			e10027: "Failed to reset or change password",
			e10028: "Failed to update user access",
			e10029: "Failed to access issue details",
			e10030: "Issue doesn't exist",
			e10031: "Failed to post-process",
			e10032: "Failed to add the reply",
			e10033: "Failed to change the reply",
			e10034: "Failed to access issue statistics",
			e10035: "Cannot delete others' replies",
			e10036: "Remark should be no longer than 20 characters",
			e10037: "gmt Interface Address Configuration Error",
			e10038: "gmt Game Key Configuration Error",
			e10039: "Failed to process the reported user",
			e10040: "Requested but gmt operation failed",
			e10041: "Report processed",
			e10042: "Please understand that IP services in your region are no longer supported!",
			e10043: "System upgrade, not yet open, please pay attention!",
		},
	}	
}