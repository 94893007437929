/**
 * 各种画echarts图表的方法都封装在这里
 * 注意：这里echarts没有采用按需引入的方式，只是为了方便学习
 */

import * as echarts from 'echarts'
const install = function(Vue) {
  Object.defineProperties(Vue.prototype, {
    $chart: {
      get() {
        return {
          optionData: {
            grid: {
              top: 10,
              bottom: 10,
              left: 0
            },
            xAxis: {
              type : 'category',
              axisLabel: {show: false},
              axisTick: {show: false},
              splitLine: {show: false},
              axisLine:{
                lineStyle:{
                  color: '#D3D3D3',
                  width: 2,
                  type: 'solid'
                }
              },
              data : ['1','2','3','4','5','6','7','8','9','10']
            },
            yAxis: {
              min:-0.5,
              max:0.5,
              type : 'value',
              position: 'left',
              splitLine:{
                show:false,
              },
              axisLine: {
                show: false
              }
            },
            series : [
              {
                name:'生活费',
                type:'bar',
                stack: '总量',
                data:[0.1,0.2,-0.2,-0.3,0.1,0.8,0.7,0.9,0.4,0.5],
                itemStyle: {
                  color: function(params){
                    //console.log(params.data);
                    if(params.data>0){
                      return '#f7b8fd'
                    }else{
                      return '#5ae8fe'
                    }
                  }
                },
                markLine : {
                  symbol:"none", 
                  lineStyle: {
                    color: '#D3D3D3',
                    width: 0.8
                  },
                  data : [
                    [
                      {
                        name: '标线2起点',
                        label:{
                          show:'true',
                          position:'end',
                          formatter:'+0.5',//这里
                          color:'#D3D3D3',//这里
                        }, x: 0, y: 20
                      },
                      {
                        name: '标线2终点', x: '80%', y: 20
                      }
                    ],
                    [
                      {name: '-0.5', x: 0, y: 100},
                      {name: '标线1终点', x: '80%', y: 100}
                    ],
                  ],
                }
              }
            ]
          },
          line1: function (id,data) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            this.optionData.series[0].data=data;
            this.chart.setOption(this.optionData);
          },
          optionData1: {
            graphic: [{
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                    text: "收益比率",
                    textAlign: 'center',
                    fill: '#5a5a5a',
                    width: 30,
                    height: 30,
                    fontSize: 14,
                    color: "#5a5a5a",
                    fontFamily: "Microsoft YaHei"
                }
            }],
            series: [
                {
                    name:'访问来源',
                    type:'pie',
                    color: ['#91ca1b' ,'#fb8500', '#ca7eb7', '#0077c9'],
                    radius: ['50%', '80%'],
                    minAngle: 5,
                    avoidLabelOverlap: false,
                    selectedMode: false,
                    silent: true,
                    label: {
                        normal: {
                            position: 'inner',
                            show: false
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    data:[
                        {value:33, name:'USDT'},
                        {value:10, name:'BTC'},
                        {value:23, name:'BQCC'},
                        {value:13, name:'ETH'}
                    ]
                }
            ]
          },
          pie1: function (id,bqccAll,ethAll,usdtAll,btcAll) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            this.optionData1.series[0].data[0].value=usdtAll;
            this.optionData1.series[0].data[1].value=btcAll;
            this.optionData1.series[0].data[2].value=bqccAll;
            this.optionData1.series[0].data[3].value=ethAll;
            this.chart.setOption(this.optionData1);
          },
          optionData2: {
            graphic: [{
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                    text: "邀请比率",
                    textAlign: 'center',
                    fill: '#5a5a5a',
                    width: 30,
                    height: 30,
                    fontSize: 14,
                    color: "#5a5a5a",
                    fontFamily: "Microsoft YaHei"
                }
            }],
            series: [
                {
                    name:'访问来源',
                    type:'pie',
                    color: ['#00b9ee', '#0077c9', '#c3c3c3'],
                    radius: ['50%', '80%'],
                    avoidLabelOverlap: false,
                    selectedMode: false,
                    silent: true,
                    minAngle: 5,
                    label: {
                        normal: {
                            position: 'inner',
                            show: false
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    data:[
                        {value:335, name:'已登录'},
                        {value:210, name:'已购买'},
                        {value:934, name:'未登录'},
                    ]
                }
            ]
          },
          pie2: function (id,purchase,user_dologin,user_login) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            // console.log(purchase+","+user_dologin+","+user_login)
            this.optionData2.series[0].data[0].value=user_login;
            this.optionData2.series[0].data[1].value=purchase;
            this.optionData2.series[0].data[2].value=user_dologin;
            this.chart.setOption(this.optionData2);
          },
          optionData3: {
            legend: {
                orient: 'vertical',
                x: 'right',
                y: 'center',
                align: 'left',
                selectedMode: false,
                data:['服务器停止','正常运行','USDT比率','BTC比率','BQCC比率','ETH比率'],
                textStyle: {
                  color: '#828282',
                  fontSize: 10,
                  fontFamily: 'Arial, "Microsoft Yahei", "Helvetica Neue", Helvetica, sans-serif'
                }
            },
            graphic: [{
                type: 'text',
                left: '22%',
                top: 'center',
                style: {
                    text: "Overview",
                    textAlign: 'center',
                    fill: '#5a5a5a',
                    width: 30,
                    height: 30,
                    fontSize: 12,
                    color: "#5a5a5a",
                    fontFamily: "Microsoft YaHei"
                }
            }],
            series: [
                {
                    name:'访问来源',
                    type:'pie',
                    center: ['30%','50%'],
                    radius: ['30%', '52%'],
                    selectedMode: false,
                    stillShowZeroSum: false,
                    minAngle: 5,
                    silent: true,
                    label: {
                        normal: {
                            position: 'inner',
                            show: false
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    data:[
                        {id: 1,value:0, name:'USDT比率'},
                        {id: 2,value:0, name:'BTC比率'},
                        {id: 3,value:0, name:'BQCC比率'},
                        {id: 4,value:0, name:'ETH比率'},
                        {id: 5,value:0, name:'暂无收益'},
                    ],
                    itemStyle: {
                      color: function (params){
                        // console.log(params.data)
                        if(params.data.id == 1){
                          return '#8dc21f';
                        }
                        if(params.data.id == 2){
                          return '#ef8200';
                        }
                        if(params.data.id == 3){
                          return '#c27bb0';
                        }
                        if(params.data.id == 4){
                          return '#0075c1';
                        }
                        if(params.data.id == 5){
                          return '#ccc';
                        }
                      }
                    }
                },
                {
                    name:'访问来源',
                    type:'pie',
                    center: ['30%','50%'],
                    radius: ['70%', '58%'],
                    minAngle: 5,
                    selectedMode: false,
                    silent: true,
                    label: {
                        normal: {
                            position: 'inner',
                            show: false
                        }
                    },
                    data:[
                        {id: 1,value:0, name:'服务器停止'},
                        {id: 2,value:0, name:'正常运行'}
                    ],
                    itemStyle: {
                      color: function (params){
                        // console.log(params.data)
                        if(params.data.id == 1){
                          return '#dcdcdc';
                        }
                        if(params.data.id == 2){
                          return '#009fe8';
                        }
                      }
                    }
                }
            ]
          },
          pie3: function (id,num,num2,bqcc,eth,btc,usdt) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            var num1 = num-num2;
            this.optionData3.series[0].data[0].value=usdt;
            this.optionData3.series[0].data[1].value=btc;
            this.optionData3.series[0].data[2].value=bqcc;
            this.optionData3.series[0].data[3].value=eth;
            if(Math.floor(usdt)==0&&Math.floor(btc)==0&&Math.floor(bqcc)==0&&Math.floor(eth)==0){
              this.optionData3.series[0].data[4].value=1;
            }else{
              this.optionData3.series[0].data[4].value=0;
            }
            this.optionData3.series[1].data[0].value=num1;
            this.optionData3.series[1].data[1].value=num2;
            this.chart.setOption(this.optionData3);
          },
          optionData4: {
              grid: {
                  left: 0,
                  top: 10,
                  right: 0,
                  bottom: 0,
                  containLabel: true,
              },
              xAxis: [
                  {
                    type: 'category',
                    boundaryGap: false,
                    data: [],
                    splitLine:{
                      show: false,
                      lineStyle: {
                        color: 'rgba(0,0,0,0.02)',
                      }
                    },
                    axisLine: {
                      show: true,
                      lineStyle: {
                        color: 'rgba(0,0,0,0.1)',
                      }
                    },
                    axisLabel: {
                      interval: 0,
                      show: true,
                      align: 'center',
                      color: '#919598',
                      fontSize: 10,
                      fontFamily: 'Arial',
                    },
                    inverse: true
                  }
              ],
              yAxis:{
                type : 'value',
                position: 'left',
                splitLine:{
                  show: true,
                  lineStyle: {
                    color: 'rgba(0,0,0,0.02)',
                  }
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: 'rgba(0,0,0,0.1)',
                  }
                },
                axisLabel: {
                  show: true,
                  color: '#919598',
                  fontSize: 8,
                  fontFamily: 'Arial',
                  formatter: function (value) {           
                   return value+'%';      
                  },
                },
                nameTextStyle: {
                  lineHeight: 166
                },
                max: 40,
                min: -40,
                splitNumber: 8
              },
              // dataZoom: [
                  // {
                  //     type: 'slider',
                  //     show: true,
                  //     xAxisIndex: [0],
                  //     start: 0,
                  //     end: 100,
                  //     bottom: 0,
                  // },
                  // {
                  //     type: 'slider',
                  //     show: true,
                  //     yAxisIndex: [0],
                  //     right: 0,
                  //     start: 0,
                  //     end: 100,
                  // },
              //     {
              //         type: 'inside',
              //         xAxisIndex: [0],
              //         start: 100,
              //         end: 0,
              //         preventDefaultMouseMove: false

              //     },
              //     {
              //         type: 'inside',
              //         yAxisIndex: [0],
              //         start: 100,
              //         end: 0,
              //         preventDefaultMouseMove: false
              //     },
              // ],
              series: [
                  {
                      name: '近期收益净值',
                      type: 'line',
                      smooth: true,
                      symbol: 'none',
                      itemStyle: {
                        color: 'rgba(255,3,145, 0.6)'
                      },
                      lineStyle: {
                        color: 'rgba(255,3,145, 0.6)'
                      },
                      areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                         [{
                              offset: 0,
                              color: 'rgba(255,3,145, 0.6)' // 0% 处的颜色
                          }, {
                              offset: 1,
                              color: 'rgba(255,3,145, 0.1)' // 100% 处的颜色
                          }])
                      },
                      data: [0.1,0.2,0.3,0.4,0.5,0.3,0.2]
                  },
                  {
                      name: '本金使用情况',
                      type: 'line',
                      smooth: true,
                      symbol: 'none',
                      itemStyle: {
                        color: 'rgba(0,255,238, 1)'
                      },
                      lineStyle: {
                        width: 1.2,
                        color: 'rgba(0,255,238, 1)'
                      },
                      areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                         [{
                              offset: 0,
                              color: 'rgba(0,255,238, 0.6)' // 0% 处的颜色
                          }, {
                              offset: 1,
                              color: 'rgba(0,255,238, 0.1)' // 100% 处的颜色
                          }])
                      },
                      data: [0.1,0.2,0.3,0.4,0.5,0.3,0.2]
                  }
              ]
          },
          line2: function (id,data1,data2,data_time) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            this.optionData4.series[0].data = data1;
            this.optionData4.series[1].data = data2;
            this.optionData4.xAxis[0].data = data_time;
            // if(data_time.length>7){
            //   this.optionData4.dataZoom[0].end = Math.floor(100-7/data_time.length*100);
            // }else{
            //   this.optionData4.dataZoom[0].end = 0;
            // }
            // console.log(data1)
            // console.log(data2)
            // console.log(data_time)
            this.chart.setOption(this.optionData4);
          },
          optionData5: {
              title: {
                  text: '近期收益净值',
                  top: 0,
                  left: 0,
                  textStyle: {
                    color: '#242B32',
                    fontSize: 14,
                    fontWeight: 'bold',
                    fontFamily: 'Arial',
                  }
              },
              grid: {
                  left: 0,
                  top: 0,
                  right:  -20,
                  bottom: -40,
                  containLabel: true,
              },
              xAxis: [
                  {
                    type: 'category',
                    boundaryGap: false,
                    show: false,
                    data: [],
                    splitLine:{
                      show: false,
                      lineStyle: {
                        color: 'rgba(0,0,0,0.02)',
                      }
                    },
                    axisLine: {
                      show: true,
                      lineStyle: {
                        color: 'rgba(0,0,0,0.1)',
                      }
                    },
                    axisLabel: {
                      interval: 0,
                      show: true,
                      align: 'center',
                      color: '#919598',
                      fontSize: 10,
                      fontFamily: 'Arial',
                    },
                    inverse: true
                  }
              ],
              yAxis:{
                type : 'value',
                position: 'right',
                show: false,
                splitLine:{
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                
                axisLabel: {
                  show: true,
                  color: '#919598',
                  fontSize: 8,
                  fontFamily: 'Arial',
                  formatter: function (value) {           
                   return value+'%';      
                  },
                },
                nameTextStyle: {
                  lineHeight: 166
                },
                max: 40,
                min: -40,
                splitNumber: 8
              },
              // dataZoom: [
              //     {
              //         type: 'inside',
              //         xAxisIndex: [0],
              //         start: 100,
              //         end: 0,
              //         preventDefaultMouseMove: false

              //     },
              //     {
              //         type: 'inside',
              //         yAxisIndex: [0],
              //         start: 100,
              //         end: 0,
              //         preventDefaultMouseMove: false
              //     },
              // ],
              series: [
                  {
                      name: '盘内成交价',
                      type: 'line',
                      smooth: true,
                      symbol: 'none',
                      lineStyle: {
                        color: '#2cb6ff',
                        width: 1,
                      },
                      areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                         [{
                              offset: 0,
                              color: 'rgba(15,165,255, 0.7)' // 0% 处的颜色
                          }, {
                              offset: 1,
                              color: 'rgba(15,165,255, 0.1)' // 100% 处的颜色
                          }])
                      },
                      data: [0.1,0.2,0.3,0.4,0.5,0.3,0.2]
                  },
                  {
                      name: '场外参考价',
                      type: 'line',
                      smooth: true,
                      symbol: 'none',
                      lineStyle: {
                        color: 'rgba(105,253,232, 1)',
                        width: 1,
                      },
                      data: [0.1,0.2,0.3,0.4,0.5,0.3,0.2]
                  }
              ]
          },
          line3: function (id,data1,data2,data_time) {
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            this.optionData5.series[0].data = data1;
            this.optionData5.series[1].data = data2;
            this.optionData5.xAxis[0].data = data_time;
            // if(data_time.length>7){
            //   this.optionData5.dataZoom[0].end = Math.floor(100-7/data_time.length*100);
            // }else{
            //   this.optionData5.dataZoom[0].end = 0;
            // }
            // console.log(data1)
            // console.log(data2)
            // console.log(data_time)
            this.chart.setOption(this.optionData5);
          },
          optionData6: {
              title: {
                  text: '近期收益年化浮动与币价走势',
                  subtext: '注:最终结算收益数额与单位以实际发生为准',
                  subtextStyle: {
                    color: '#919598',
                    fontSize: 8,
                    top: 40
                  },
                  top: 26,
                  left: 40,
                  textStyle: {
                    color: '#333',
                    fontSize: 14,
                    fontWeight: 'bold',
                    fontFamily: 'Arial',
                  }
              },
              legend: {
                top: 70,
                left: 40,
                orient: 'vertical',
                textStyle: {
                  color: '#919598',
                  fontSize: 8,
                  fontFamily: 'Arial, "Microsoft Yahei", "Helvetica Neue", Helvetica, sans-serif'
                },
                itemWidth: 16,
                itemheight: 16,
              },
              grid: {
                  left: 40,
                  top: 30,
                  right: 26,
                  bottom: 26,
                  containLabel: true,
              },
              xAxis: [
                  {
                    type: 'category',
                    boundaryGap: false,
                    splitLine:{
                      show: false,
                      lineStyle: {
                        color: 'rgba(0,0,0,0.02)',
                      }
                    },
                    axisLine: {
                      show: true,
                      lineStyle: {
                        color: 'rgba(0,0,0,0.1)',
                      }
                    },
                    axisLabel: {
                      show: true,
                      align: 'center',
                      color: '#919598',
                      fontSize: 10,
                      fontFamily: 'Arial',
                    },
                    inverse: true
                  }
              ],
              yAxis:{
                type : 'value',
                position: 'right',
                splitLine:{
                  show: true,
                  lineStyle: {
                    color: 'rgba(0,0,0,0.02)',
                  }
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: 'rgba(0,0,0,0.1)',
                  }
                },
                axisLabel: {
                  show: true,
                  color: '#919598',
                  fontSize: 8,
                  fontFamily: 'Arial',
                  formatter: function (value) {           
                  return value.toFixed(2)+'%';      
                  },
                },
                nameTextStyle: {
                  lineHeight: 166
                },
                // scale: true,
                // minInterval: 0.10,
                // interval: 0.05,
                max: 40,
                min: -40,
                splitNumber: 8
              },
              // dataZoom: [
                  // {
                  //     type: 'slider',
                  //     show: true,
                  //     xAxisIndex: [0],
                  //     start: 0,
                  //     end: 100,
                  //     bottom: 0,
                  // },
                  // {
                  //     type: 'slider',
                  //     show: true,
                  //     yAxisIndex: [0],
                  //     right: 0,
                  //     start: 0,
                  //     end: 100,
                  // },
              //     {
              //         type: 'inside',
              //         xAxisIndex: [0],
              //         start: 100,
              //         end: 0,
              //         preventDefaultMouseMove: false

              //     },
              //     {
              //         type: 'inside',
              //         yAxisIndex: [0],
              //         start: 100,
              //         end: 0,
              //         preventDefaultMouseMove: false
              //     },
              // ],
              series: [
                  {
                      name: '产品年化浮动情况',
                      type: 'line',
                      smooth: true,
                      symbol: 'none',
                      itemStyle: {
                        color: 'rgba(162,171,221, 0.7)'
                      },
                      lineStyle: {
                        color: 'rgba(162,171,221, 0.7)'
                      },
                      areaStyle: {
                        color:{
                          type: 'linear',
                          x: 0,
                          y: 1,
                          x2: 0,
                          y2: 0,
                          colorStops: [{
                              offset: 0,
                              color: 'rgba(162,171,221, 0.4)' // 0% 处的颜色
                          }, {
                              offset: 1,
                              color: 'rgba(162,171,221, 1)' // 100% 处的颜色
                          }],
                        }
                      },
                      data: [0.1,0.2,0.3,0.4,0.5,0.3,0.2]
                  },
                  {
                      name: 'USDT价格浮动情况',
                      type: 'line',
                      smooth: true,
                      symbol: 'none',
                      itemStyle: {
                        color: 'rgba(239,35,42, 0.7)'
                      },
                      lineStyle: {
                        width: 1.2,
                        color: 'rgba(239,35,42, 0.7)'
                      },
                      data: [0.1,0.2,0.3,0.4,0.5,0.3,0.2]
                  }
              ]
          },
          line6: function (id,type,data1,data2,data_time) {
            if(type==1){
              this.optionData6.series[1].name = 'USDT价格浮动情况';
            }
            if(type==2){
              this.optionData6.series[1].name = 'BTC价格浮动情况';
            }
            if(type==3){
              this.optionData6.series[1].name = 'ETH价格浮动情况';
            }
            this.chart = echarts.init(document.getElementById(id));
            this.chart.clear();
            this.optionData6.series[0].data = data1;
            this.optionData6.series[1].data = data2;
            this.optionData6.xAxis[0].data = data_time;
            // if(data_time.length>7){
            //   this.optionData6.dataZoom[0].end = Math.floor(100-7/data_time.length*100);
            // }else{
            //   this.optionData6.dataZoom[0].end = 0;
            // }
            // console.log(data1)
            // console.log(data2)
            // console.log(data_time)
            this.chart.setOption(this.optionData6);
          }
        }
      }
    }
  })
}

export default {
    install
}
