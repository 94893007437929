import Vue from 'vue'
import router from './router'
import App from './App.vue'
import '@/assets/styles/iconfont/iconfont.css'
import '@/assets/styles/reset.css'
import '@/assets/styles/border.css'
import i18n from './i18n/i18n'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/style/theme/index.css'
import ElementUI from 'element-ui';
import myCharts from './assets/js/myCharts.js'
import moment from 'moment'

Vue.use(ElementUI);
Vue.use(myCharts);
Vue.filter('dateformat', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr*1000).format(pattern)
})
Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
