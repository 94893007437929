<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  beforeCreate () {    
      if(localStorage.token==""||localStorage.token==null||localStorage.token==undefined){     
          this.$router.push('/');
          localStorage.mobile = '';
          localStorage.orderId = '';
          localStorage.loginStyle = '';
          localStorage.token = '';
      }
  }
}
</script>

<style>
</style>
