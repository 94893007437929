module.exports = {
	lang: {
		'暂无该订单信息,请核对订单id后重试': '暂无该订单信息,请核对订单id后重试',
		'登录失败': '登录失败',
		'登录信息错误': '登录信息错误',
		'管理员验证': '管理员验证',
		'管理员账号': '管理员账号',
		'管理员密码': '管理员密码',
		'请输入管理员账号': '请输入管理员账号',
		'请输入管理员密码': '请输入管理员密码',
		'暂无数据': '暂无数据',
		'刷新': '刷新',
		'请输入正确的UID，否则无法获得交易返佣': '请输入正确的UID，否则无法获得交易返佣',
		'绑定UID': '绑定UID',
		'绑定API': '绑定API',
		'选择平台': '选择平台',
		'注：适用于新用户新增API操作。': '注：适用于新用户新增API操作。',
		'注：适用于原平台原账号API到期换新操作，保留全部数据继续执行。': '注：适用于原平台原账号API到期换新操作，保留全部数据继续执行。',
		'注：适用于更换平台或账号API，并平仓掉原账号持仓资产。': '注：适用于更换平台或账号API，并平仓掉原账号持仓资产。',
		'注：忽略原有账号数据及资产，直接更换新平台新账号API操作。': '注：忽略原有账号数据及资产，直接更换新平台新账号API操作。',
		'新增API': '新增API',
		'原API到期续期': '原API到期续期',
		'平仓换新号API': '平仓换新号API',
		'直接换新号API': '直接换新号API',
		'初始金额不能为空': '初始金额不能为空',
		'初始时间不能为空': '初始时间不能为空',
		'确认提交吗？': '确认提交吗？',
		'提币金额不能为空': '提币金额不能为空',
		'充值金额不能为空': '充值金额不能为空',
		'数据不能为空': '数据不能为空',
		'保存成功': '保存成功',
		'确认保存参数设置吗？': '确认保存参数设置吗？',
		'API更换成功': 'API更换成功',
		'API添加成功': 'API添加成功',
		'确定绑定吗？': '确定绑定吗？',
		'请输入账号的UID': '请输入账号的UID',
		'UID无效': 'UID无效',
		'请输入Access Key': '请输入Access Key',
		'请输入Secret Key': '请输入Secret Key',
		'开启': 'Open',
		'关闭': 'Close',
		'暂未开放': '暂未开放',
		'恢复上次设置操作成功': '恢复上次设置操作成功',
		'暂无可恢复的设置，请添加参数并保存参数设置': '暂无可恢复的设置，请添加参数并保存参数设置',
		'请自行登录账号所在平台前端查看': '请自行登录账号所在平台前端查看',
		'确认平仓吗？': '确认平仓吗？',
		'策略正在运行，请关闭后再操作': '策略正在运行，请关闭后再操作',
		'API失效': 'API失效',
		'平仓成功': '平仓成功',
		'确认提示': '确认提示',
		'提示': '提示',
		'确定': '确定',
		'取消': '取消',
		'确定退出吗？':'确定退出吗？',
		'天': '天',
		'小时': '小时',
		'设置': '设置',
		'币种': '币种',
		'市场价': '市场价',
		'24H额': '24H额',
		'流通数量': '流通数量',
		'24H涨幅': '24H涨幅',
		'暂无': '暂无',
		'我的工具系统': '我的工具系统',
		'暂无该订单信息,请核对订单id后重试': '暂无该订单信息,请核对订单id后重试！',
		wallet: '资产 / 钱包',
		loginText: '登录',
		loginOutText: '退出登录',
		register: '注册',
		ranking: '涨幅排行榜',
		jiaoyi: {
			text1: 'Ai智能交易系统24H总额',
			text2: '系统24H交易单量',
			text3: '线程运行数量',
			text4: '综合年化收益率',
			text5: '当前数据统计不包含用户通过本平台Ai智能系统为平台外部交易产生的数据，所有数据24小时刷新；',
			buy: '买单',
			sell: '卖单'
		},
		robotSet: {
			title1: '系统设置',
			switch: '系统开关',
			apiset: 'API 设置',
			howset: '如何设置',
			workpt: '运行平台',
			ptuid: '平台ID',
			mobile: '关联账号',
			xiugai: '修改',
			orderid: '订单编号',
			stxj: '系统巡检',
			stsx: '系统时效',
			xufei: '续费',
			btn1: '智能参数跟随',
			btn2: '自定义参数设置',
			btn3: '系统参数重置',
			btn4: '返回系统页面',
			btn5: '返回上次参数',
			btn6: '保存参数设置',
			coinset1: '分仓管理',
			coinset2: '工作区间',
			coinset3: '买单条件',
			coinset4: '止盈条件',
			coinset5: '首单买进',
			coinset6: '上限价格',
			coinset7: '买单间距',
			coinset8: '止盈方式',
			coinset9: '循环时间',
			coinset10: '下限价格',
			coinset11: '买单笔数',
			coinset12: '刷新时间',
			coinset13: '最多同时支持4个交易对',
			'系统默认': '系统默认',
			'手动设置': '手动设置',
			'单笔止盈': '单笔止盈',
			'多笔止盈': '多笔止盈',
			'API正常': 'API正常',
			'API失效': 'API失效',
		},
		mycount: {
			text1: '运行情况',
			text2: '开始时间',
			text3: '运行时长',
			text4: '初始金额',
			text5: '充值总额',
			text6: '提币总额',
			text7: '当前金额',
			text8: '持仓金额',
			text9: '当前总值',
			text10: '当前利润',
			text11: '当前收益',
			text12: '预期年化',
			text13: '请输入充值金额',
			text14: '请输入提币金额',
			tab: '我的系统工具',
			biaoti: '我的账户',
			zhuangtai: '运行状态',
			ccqk: '持仓情况',
			gzzt: '工作状态',
			lscj: '历史成交',
			coinname: '品类',
			num: '数量',
			ccjj: '持仓均价',
			total: '当前价值',
			time: '时间',
			direction: '方向',
			price: '价格',
			money: '金额',
			zanwu: '暂无数据',
			pingcang: '一键平仓',
			sysclose: '系统关闭',
			sysopen: '系统开启',
			'选择日期': '选择日期',
			'请输入正整数': '请输入正整数',
			zhu: '注：设置初始金额会影响仓位管理情况，请谨慎填写',
			buy: '买',
			sell: '卖'
		},
		introduce: {
			tit1: '为您提供前所未有的多维度平台服务',
			item1: {
				text1: '全网深度聚合交易',
				text2: '盘口饱满·交易顺畅',
			},
			item2: {
				text1: '智能Ai100交易模块',
				text2: '智能·自动·可视化',
			},
			item3: {
				text1: '100%资产透明体系',
				text2: '安全·透明·可信',
			},
			item4: {
				text1: '国际标准资管服务',
				text2: '多品类·高收益',
			}
		},
		international: {
			tit1: '践行国际化 / 合规化 / 透明化运营体系',
			item1: {
				text1: '加拿大MSB牌照',
			},
			item2: {
				text1: '美国MSB牌照',
			},
			item3: {
				text1: '新加坡MAS牌照',
			},
			item4: {
				text1: '澳大利亚AUSTR牌照',
			}
		},
		download: {
			text1: '多端互动·随时随地畅享交易',
			text2: '平台互通，使用更便捷 ！'
		},
		footer: {
			item1: {
				tit: '用户支持',
				text1: '帮助中心',
				text2: 'API文档',
				text3: '费率',
				text4: '系统公告',
			},
			item2: {
				tit: '服务条款',
				text1: '用户协议',
				text2: '隐私条款',
				text3: '法律声明',
			},
			item3: {
				tit: '招募&合作',
				text1: 'C2C功能服务',
				text2: '申请机构账户',
				text3: '上币申请',
				text4: '城市节点招募'
			},
			item4: {
				tit: 'C2C功能服务',
				text1: 'About 100SunEX',
				text2: 'About SUNT',
			}
		},
		systemGg: {
			text1: '系统文档管理中心',
			text2: '系统公告',
			text3: '常见问题',
			text4: '帮助中心',
			xiangguan: '相关内容',
		},
		'关于100SunEX近期暂停交易与国际版升级计划的公告；': '关于100SunEX近期暂停交易与国际版升级计划的公告',
		'尊敬的各位用户：': '尊敬的各位用户：',
		systemList: {
			pt: '支持平台',
			status_text: '服务状态',
			status_desc: '暂未开放',
			status_desc1: '正常开放',
			item1: {
				title1: 'USDT 本位 - 现货Ai自动交易工具系统',
				title2: '各平台主流Token现货无杠杆自动化交易',
			},
			item2: {
				title1: 'USDT本位 - DEX盘口/套利自动交易工具',
				title2: '各平台主流Token现货无杠杆自动化交易',
			},
			item3: {
				title1: 'TOKEN 本位 - 现货Ai自动交易工具系统',
				title2: '各平台主流Token合约全仓杠杆自动化交易',
			}
		},
		look: '查看更多行情',
		title: '客服工单系统',
		modifypass: '修改密码',
		modifypass1: '修改密码',
		oldPassword: '原密码：',
		newPassword: '新密码：',
		surePassword: '确认新密码：',
		loginout: '退出',
		cancel: '取 消',
		sure: '确 定',
		welcome: '欢迎使用 客服工单系统',
		menus: {
			menu1: '首页',
			menu2: '未完成问题',
			menu3: '已完成问题',
			menu4: '统计信息',
			menu5: '用户管理',
			menu6: '帮助',
			menu7: '举报管理',
		},
		navigation: {
			infos: {
				text: 'C2C市场',
				text1: '行情',
				text2: 'CEX交易',
				// text3: 'DEX交易',
				text3: '常用平台',
				text4: 'Ai100量化工具',
				// text4: 'Ai100工具系统',
				text5: '金融服务',
				text6: '更多',
				text7: '100SunGlobal',
				text8: '100SunDSEC',
				text9: 'SunPool',
				text10: 'About SUNT',
			},
			dex: {
				text1: 'Binance',
				text2: 'Huobi',
				text3: 'OKEX',
				text4: 'UniSwap',
				text5: 'PancakesSwap',
				text6: 'MDEX',
				text7: 'DYDX',
				text8: 'Coinmarket cap',
			}
		},
		login: {
			tit1: '账号密码登录',
			tit2: '订单编号登录',
			placeholder1: '请输入您的账号',
			placeholder2: '请输入登录密码',
			placeholder3: '请输入您的系统订单号',
			fotgetPass: '忘记密码',
			confirmLogin: '确认登录',
			modifypassSucc: '修改密码成功！',
			error1: '原密码不能为空！',
			error2: '密码长度为6-18位！',
			error3: '新密码不能为空！',
			error4: '确认密码不能为空！',
			error5: '两次输入密码不一致，请重新输入！',
			error6: '订单编号不能为空！',
		},
		error: {
			e0: "操作成功",
			e10001: "无效的用户",
			e10002: "密码错误",
			e10003: "程序内部错误",
			e10004: "用户已存在",
			e10005: "注册失败",
			e10006: "用户不存在",
			e10007: "网络错误",
			e10008: "用户没有此权限",
			e10009: "参数错误",
			e10010: "数据库错误",
			e10011: "定时任务错误",
			e10012: "数据错误",
			e10013: "操作超时",
			e10017: "程序异常",
			e10018: "口令已失效",
			e10019: "回复不存在",
			e10020: "session失效",
			e10021: "用户名只能含有字母数字和下划线，不能以数字开头",
			e10022: "添加新用户失败",
			e10023: "密码格式不正确",
			e10024: "不能使用原来的密码",
			e10025: "被重置密码的管理员不存在",
			e10026: "超管密码不能被重置",
			e10027: "重置或修改密码失败",
			e10028: "更新用户权限失败",
			e10029: "查询问题详情失败",
			e10030: "问题不存在",
			e10031: "后送处理失败",
			e10032: "添加回复失败",
			e10033: "修改回复失败",
			e10034: "查询问题统计信息失败",
			e10035: "不能删除别人的回复",
			e10036: "备注的长度最大为20个字符",
			e10037: "gmt接口地址配置错误",
			e10038: "gmt游戏秘钥配置错误",
			e10039: "处理被举报用户失败",
			e10040: "请求成功但是gmt执行失败",
			e10041: "此举报已被处理过",
			e10042: "暂不支持您所在的地区IP提供服务，敬请谅解！",
			e10043: "系统升级，暂未开放，敬请关注！",
		},
	}
}