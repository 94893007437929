import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const router = new Router ({
  routes: [
    {
      path: '/Ai100',
      name: 'Ai100',
      component: () => import('./pages/Ai100/Ai100')
    },
    {
      path: '/RobotSetDetails',
      name: 'RobotSetDetails',
      component: () => import('./pages/Ai100/RobotSetDetails')
      
    },
    {
      path: '/SysRobotDetails',
      name: 'SysRobotDetails',
      component: () => import('./pages/Ai100/SysRobotDetails')
    },
    {
      path: '/MyToolSystem',
      name: 'MyToolSystem',
      component: () => import('./pages/Ai100/MyToolSystem')
    },
    {
      path: '/Login',
      name: 'Login',
      component: () => import('./pages/Login/Index')
    },
    {
      path: '/MoreRank',
      name: 'MoreRank',
      component: () => import('./pages/MorePage/MoreRank')
    },
    {
      path: '/',
      name: 'verification',
      component: () => import('./pages/Login/verification')
    },
  ]
})

export default router